<template>
        <div :class="`${($store.getters['display/getAside'])?'contents expanded':'contents'}`" >

            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main">
                            <h4 class="text-capitalize breadcrumb-title" >{{ t('addlotto',{},{locale:getLocal}) }} จำนวนที่แสดง :{{listLottoData.length}}/{{countAllFile}}</h4>
                            <div class="breadcrumb-action justify-content-center flex-wrap">            
                                <div class="action-btn">
                                    <a href="#" class="btn btn-sm btn-primary btn-add" data-toggle="modal" data-target="#new-member">
                                        <i class="la la-plus"></i>{{ t('addlotto',{},{locale:getLocal}) }}</a>
                                </div>
                                <!-- <div class="action-btn">
                                    <a @click="orderBy" class="btn btn-sm btn-primary btn-add" >
                                        <i class="la la-plus"></i>Order</a>
                                </div> -->
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row">
  
                    <div class="col-lg-12 mb-30">
                        <div class="card">
                            <div class="my-card-header color-dark fw-500 pt-3">
                                <div class="row">
                                    <div class="col-md-3 col-12 py-2 px-4">
                                        <div class="d-flex">
                                            <p>1.อัพโหลด water Mark:</p>
                                            <input type="file" ref="watermarkInput" />
                                        </div>
                                        <div class="d-flex">
                                            <p>2.อัพโหลด Rotto:</p>
                                            <input type="file" id="imageMulti" ref="originalInput" @change="startUploadMulti" name="imageMulti" multiple accept="image/*" />
                                        </div>
                                        
                            
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3 col-12">
                                        <span class="max-w-px-200 my-dropdown">
                                            <label for="drawID_Data">{{ t('Did',{},{locale:getLocal}) }} </label>
                                            <input v-model="drawID_Data" type="text">
                                        </span>
                                    </div>
                                    <div class="col-md-3 col-12">
                                        <span class="max-w-px-200 my-dropdown">
                                            <label for="drawDateTH_Data">{{ t('drawdateth',{},{locale:getLocal}) }} </label>
                                            <input v-model="drawDateTH_Data" type="text">
                                        </span>
                                    </div>
                                    <div class="col-md-3 col-12">
                                        <span class="max-w-px-200 my-dropdown">
                                            <label for="drawDateEN_Data">{{ t('drawdateen',{},{locale:getLocal}) }} </label>
                                            <input v-model="drawDateEN_Data" type="text">
                                        </span>
                                    </div>
                                    <div class="col-md-3 col-12">
                                        <span class="max-w-px-200 my-dropdown">
                                            <label for="barcodeBackData">Barcode Back </label>
                                            <input v-model="barcodeBackData" type="text">
                                        </span>
                                    </div>
                                    <div class="col-md-3 col-12">
                                        <span class="max-w-px-200 my-dropdown">
                                            <label for="lottoRefNumberData">Lotto Ref Number</label>
                                            <input v-model="lottoRefNumberData" type="text">
                                        </span>
                                    </div>
                                    <div class="col-md-3 col-12">
                                        <span class="max-w-px-200 my-dropdown">
                                            <label for="lottoSetNumberData">Lotto Set Number</label>
                                            <input v-model="lottoSetNumberData" type="text">
                                        </span>
                                    </div>
                                </div>
                            </div> 
                            <div class="card-body">

                                <div v-for="(e,index) in dataOrder" :key="index" :class="`m-3 row card-rotto ${e.isError?'card-rotto-color-red':'card-rotto-color-gold'}`">
                                    <div class="col-12">
                                        <h4 class="text-center" >{{e.fileName}}</h4>
                                    </div>
                                    <div class="col-12">
                                        <hr class="my-2">
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <!-- <canvas :ref="`canvas${index}`"></canvas> -->
                                        <img class="w-100" :src="e.imagesrcWatermark" alt="">
                                    
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="row">
                                            <div class="col-md-6 col-12 ">
                                                <label for="lottoNumber">{{ t('lottonumber',{},{locale:getLocal}) }}</label>
                                                <div class="position-relative">
                                                    <input  type="text" maxlength="6" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" name="lottoNumber" v-model="e.lottoNumber" class="form-control input-bold" :placeholder="t('lottonumber',{},{locale:getLocal})">
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12 ">
                                                <label for="drawId">{{ t('Did',{},{locale:getLocal}) }}</label>
                                                <div class="position-relative">
                                                    <input  type="text" name="drawId" v-model="e.drawId" class="form-control" :placeholder="t('Did',{},{locale:getLocal})">
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 col-12 ">
                                                <label for="drawNumber">{{ t('Dnumber',{},{locale:getLocal}) }}</label>
                                                <div class="position-relative">
                                                    <input  type="text" maxlength="2" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" name="drawNumber" v-model="e.drawNumber" class="form-control input-bold" :placeholder="t('Dnumber',{},{locale:getLocal})">
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12 ">
                                                <label for="setNumber">{{ t('setnumber',{},{locale:getLocal}) }}</label>
                                                <div class="position-relative">
                                                    <input  type="text" maxlength="2" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" name="setNumber" v-model="e.setNumber" class="form-control input-bold" :placeholder="t('setnumber',{},{locale:getLocal})">
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 col-12 ">
                                                <label for="drawDateTh">{{ t('drawdateth',{},{locale:getLocal}) }}</label>
                                                <div class="position-relative">
                                                    <input  type="text" name="drawDateTh" v-model="e.drawDateTh" class="form-control" :placeholder="t('drawdateth',{},{locale:getLocal})">
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12 ">
                                                <label for="drawDateEn">{{ t('drawdateen',{},{locale:getLocal}) }}</label>
                                                <div class="position-relative">
                                                    <input  type="text" name="drawDateEn" v-model="e.drawDateEn" class="form-control" :placeholder="t('drawdateen',{},{locale:getLocal})">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 col-12 ">
                                                <label for="lottoRefNumber">{{ t('lottorefnum',{},{locale:getLocal}) }}</label>
                                                <div class="position-relative">
                                                    <input  type="text" maxlength="7" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" name="lottoRefNumber" v-model="e.lottoRefNumber" class="form-control" :placeholder="t('lottorefnum',{},{locale:getLocal})">
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12 ">
                                                <label for="price">{{ t('price',{},{locale:getLocal}) }}</label>
                                                <div class="position-relative">
                                                    <input disabled type="text" name="price" v-model="e.price" class="form-control" :placeholder="t('price',{},{locale:getLocal})">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 col-12 ">
                                                <label for="Barcode Front">Barcode Front</label>
                                                <div class="position-relative">
                                                    <input  type="text" name="Barcode Front" v-model="e.lottoBarcodeRawData" class="form-control" placeholder="Barcode Front">
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12 ">
                                                <label for="barcodeBack">Barcode Back</label>
                                                <div class="position-relative">
                                                    <input  type="text" name="barcodeBack" v-model="e.barcodeBack" class="form-control input-bold" placeholder="Barcode Back">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <a v-if="!e.isUpload" @click="confirmLotto(e)" :class="`btn btn-sm ${e.isError?'btn-err':'btn-primary'}  btn-add w-100`" style="color:white;"><i class="la la-plus"></i> {{ t('addlotto',{},{locale:getLocal}) }}</a>
                                        <a v-else class="btn btn-sm btn-primary btn-add w-100" style="color:white; background-color: green !important;" disabled="true"><i class="la la-plus"></i> {{ t('uploaded',{},{locale:getLocal}) }}</a>
                                    </div>
                                </div>
                            

                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
</template>

<script>
// @ is an alias to /src

import * as serviceAPI  from '../services/API.service';
import * as serviceMain  from '../services/main.service'
import * as constant from '../services/constant';;
import Pagination from '@/components/Pagination.vue'
import { useI18n } from 'vue-i18n'
export default {
  name: 'AddLottery',
  setup() {
      const {t, locale} = useI18n();
      return {t, locale};
  },
  data(){
    return{
      listLottoData:[],
      drawID_Data:'',
      drawDateTH_Data:'',
      drawDateEN_Data:'',
      barcodeBackData:'',
      lottoRefNumberData:'',
      lottoSetNumberData:'',
      countAllFile:0,
    }
  },
  methods: {
    async confirmLotto(e){
        try {
            let form = new FormData();
            form.append("drawId", e.drawId);
            form.append("lottoNumber", e.lottoNumber);
            form.append("setNumber", e.setNumber);
            form.append("drawDateTh", e.drawDateTh);
            form.append("drawDateEn", e.drawDateEn);
            form.append("drawNumber", e.drawNumber);
            form.append("lottoRefNumber", e.lottoRefNumber);
            form.append("lottoSetNumber", e.lottoSetNumber);
            form.append("lottoBarcodeRawData", e.lottoBarcodeRawData);
            form.append("lottoBarcodeTextAppear",`${e.lottoBarcodeRawData.trim()}-${e.barcodeBack}` );
            form.append("price", e.price);
            form.append("image", e.image);
            form.append("maskedImage", e.maskedImage);
    
            let getData = await serviceAPI.call_API_FORMDATA("POST","lotto/api/v1/addLotto",form);
            e.isUpload=true
        } catch (error) {
            serviceMain.showErrorAlert(this,error)
        }
    },  
    async startUploadMulti(e) {
        try {
            this.listLottoData=[]
            let _this = this;
            let getIMGlength = this.$refs.originalInput.files.length
            console.log("getIMGlength",getIMGlength)
            this.countAllFile = getIMGlength;
            for (var i = 0; i < getIMGlength; i++) {
                // fd.append("fileToUpload[]", document.getElementById('fileToUpload').files[x]);

                let getIMG = document.getElementById('imageMulti').files[i]
                const watermarkFile = this.$refs.watermarkInput.files[0];
                let originalFile = getIMG;
                
                    const targetImg = await this.loadImage(URL.createObjectURL(originalFile));
                    const watermarkImg = await this.loadImage(URL.createObjectURL(watermarkFile));
                    console.log(targetImg)
                    console.log(watermarkImg)
                    const canvas = document.createElement('canvas');
                    // const canvas = this.$refs.canvas;
                    const ctx = canvas.getContext('2d')
                    // Set canvas dimensions to the target image
                    canvas.width = targetImg.width;
                    canvas.height = targetImg.height;

                    // Draw the target image on the canvas
                    ctx.drawImage(targetImg, 0, 0);

                    // Draw the watermark image on the canvas
                    ctx.drawImage(watermarkImg, canvas.width - watermarkImg.width, canvas.height - watermarkImg.height);
                    
                

                let reader = new FileReader();
                reader.onload = async (e) => {
                    let form = new FormData();
                    form.append("file", getIMG);
                    // console.log(getIMG);
                    let getdataOCR = await serviceAPI.call_OCR(form,getIMG.name)
                    // console.log("🚀 ~ file: AddLottery.vue ~ line 161 ~ startUploadMulti ~ getdataOCR", getdataOCR)
                    // QR_data: (4) ["64", "30", "49", "597648"]
                    //     drawNumber: "30"
                    //     lottoNumber: "597648"
                    //     setNumber: "49"
                    // drawDate: "1 สิงหาคม 2564"
                    // drawDateENG: "1 AUGUST 2021"
                    // drawNumber: "30"
                    // lottoBarcode: "2130492604504604"
                    // lottoBarcodeTextAppear: "2130492604504604-4623"
                    // lottoNumber: "597648"
                    // lottoRefNumber: "3068018"
                    // setNumber: "49"
                    // let mergedImg = await this.getImageFromCanvas(canvas);

                    const imgMarkName = 'masked_'+getIMG.name
                    let mergedSrcBase64 = await canvas.toDataURL('image/jpeg');
                    const block = await mergedSrcBase64.split(";");
                    const contentType = await block[0].split(":")[1];
                    const realData = await block[1].split(",")[1];
                    const blob = await this.b64toBlob(realData, contentType);
                    const mergedFile = await new File([blob], imgMarkName, { type: contentType });
                    let getSRC = e.target.result
                    let getSRCWatermark = mergedSrcBase64
                    let jsonData={
                        drawId:this.drawID_Data,
                        lottoNumber:'',
                        setNumber:this.lottoSetNumberData,
                        drawDateTh:this.drawDateTH_Data,
                        drawDateEn:this.drawDateEN_Data,
                        drawNumber:'',
                        lottoRefNumber:this.lottoRefNumberData,
                        lottoBarcodeRawData:'',
                        lottoBarcodeTextAppear:'',
                        barcodeBack:this.barcodeBackData,
                        price:'80',
                        image:getIMG,
                        imagesrc:getSRC,
                        
                        isUpload:false,
                        isError:true,
                        fileName:getIMG.name,
                        maskedImage: mergedFile,
                        fileNameMasked: imgMarkName,
                        imagesrcWatermark:getSRCWatermark,
                    }
                    let getback='';
                    if(getdataOCR.status=='1'){
                        if(this.barcodeBackData==''){
                            if(getdataOCR.lottoBarcodeTextAppear.includes("-")){
                                getback = getdataOCR.lottoBarcodeTextAppear.split("-")[1]
                            }
                        }
                        
                        jsonData={
                            drawId:this.drawID_Data,
                            lottoNumber:getdataOCR.QR.lottoNumber,
                            setNumber:this.lottoSetNumberData==''?getdataOCR.QR.setNumber:this.lottoSetNumberData,
                            drawDateTh:this.drawDateTH_Data==''?getdataOCR.drawDate:this.drawDateTH_Data,
                            drawDateEn:this.drawDateEN_Data==''?getdataOCR.drawDateENG:this.drawDateEN_Data,
                            drawNumber:getdataOCR.QR.drawNumber,
                            lottoRefNumber:this.lottoRefNumberData==''?getdataOCR.lottoRefNumber:this.lottoRefNumberData,
                            lottoBarcodeRawData:getdataOCR.lottoBarcode,
                            lottoBarcodeTextAppear:getdataOCR.lottoBarcodeTextAppear,
                            barcodeBack:this.barcodeBackData==''?getback:this.barcodeBackData,
                            price:'80',
                            image:getIMG,
                            imagesrc:getSRC,
                            isUpload:false,
                            isError:false,
                            fileName:getIMG.name,
                            maskedImage: mergedFile,
                            fileNameMasked: imgMarkName,
                            imagesrcWatermark:getSRCWatermark,
                        }
                    }
                    
                    
                    _this.listLottoData.push(jsonData)
                };  
                
                reader.readAsDataURL(getIMG);

                
            }
        } catch (error) {
            serviceMain.showErrorAlert(this,error)
        }
    },
    async b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
      
    },
    updateCanvasImage(e) {
      this.status = 'initialize'
      var self = this;
      
      var reader, files = e.target.files;
      
      var reader = new FileReader();
      
      reader.onload = (e) => {
        var img = new Image();
        img.onload = function() {
          self.drawCanvasImage(img)
        }
        img.src = event.target.result;
      };
      
      reader.readAsDataURL(files[0]);
      
    },
    
    drawCanvasImage(img) {
      var vm = this
      var canvas = this.$refs.imageCanvas;
      canvas.width = img.width;
      canvas.height = img.height;
      
      var ctx = canvas.getContext('2d');
      ctx.drawImage(img,0,0);
      this.dataUrl = canvas.toDataURL();
      this.status = 'reading'
      Tesseract.recognize(this.dataUrl, 'eng', {
        logger: log => {
          
          console.log(log);
          
        }
      })
      .then(result => {
        console.log(result)
        vm.textResult=result.data.text;
        vm.status = ''
      })
      .catch(error => console.log(error))
      .finally(() => {
        
        
      });
    },
    readFile(e,id) {
    // <input id="inp${result.data[i]._id}" onchange="readFile(this,'${result.data[i]._id}')" type='file' style="padding:5px 15px 15px 15px;">
    // <img id="img${result.data[i]._id}" style="width:100%;">
    // for (var x = 0; x < ins; x++) {
    //     fd.append("fileToUpload[]", document.getElementById('fileToUpload').files[x]);
    // }
      if (e.files && e.files[0]) {
        
        var FR= new FileReader();
        
        FR.addEventListener("load", function(e) {
           document.getElementById("img"+id).src       = e.target.result;
          //document.getElementById("b64").innerHTML = e.target.result;
            var slipimg =e.target.result;
          var getuserid = readCookie("USERID");
          on();
            $.post("/orders/updatewaittocheck", {
                    "userid": getuserid,
                    "_id": id,
                    "slipimg":slipimg
                },
                function (data, status) {
                    //console.log("Data: " + data + "\nStatus: " + status);

                    if (status == 'success') { 
                        off()
                  
                        // confirmButtonColor: '#B8292F',
                        // cancelButtonColor: '#B4B4B4',
                        Swal.fire({
                        html: `<h2>อัพโหลดสลิปโอนเงินแล้ว</h2>`,
                        showCancelButton: true,
                        confirmButtonText: 'ตกลง',
                        cancelButtonText: 'เลือกรูปใหม่'
                        }).then((result) => {
                        if (result.value) {
                            
                            location.href = "/payments";
                            }
                        })
                    
                    }
                });
        }); 
        
        FR.readAsDataURL( e.files[0] );
      }
      
    },
    orderBy(){
        try {
            
        } catch (error) {
            
        }
    },
    loadImage(src) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = () => resolve(img);
          img.onerror = reject;
        });
      },
      getImageFromCanvas(canvas) {
      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/png');
      });
    },
  },
  
  computed: {
      getLocal:function(){
      return this.$store.getters['display/getLocal']
    },
    dataOrder:function(){
        return this.listLottoData.sort((a, b) => a.fileName.localeCompare(b.fileName))
    }
    
  },
    
}
</script>

<style scope>

    .table-responsive{
        overflow-y: auto;
        max-height: 70vh;
    }
    .btn-action-cancel{
        background-color: rgb(245, 221, 37);
    }
    .btn-action-close{
        background-color: orange ;
    }
    .btn-action-delete{
        background-color: rgb(226, 80, 80) ;
    }
    .btn-action-view{
        background-color: rgb(3, 117, 238) ;
    }
    .btn-action{
        border: none;
        border-radius: 4px;
        padding: 5px 10px;
        color: white;
        margin: 0px 5px;
    }
    .img-size{
        max-height: 100px;
      
    }
    .card-rotto{
        
        border-radius: 8px;
        padding: 15px;
        
    }
    .card-rotto-color-gold{
        border:5px rgba(238, 166, 58, 0.75) solid ;
    }
    .card-rotto-color-red{
        border:5px red solid ;
    }
    .input-bold{
        font-weight: 900;
        font-size: 1.5rem;
    }
    .btn-err{
        background-color:red;
    }
</style>